import axios from 'axios'
const instance = axios.create({
    timeout: 5000
});

instance.interceptors.request.use(
    function (config) {
        if(config.url.substring(0, 5)=="/api/") {
            config.url = "/rest" + config.url;
        }
        return config;
    }, 
    function (error) {
        return Promise.reject(error);
    }
);

/*
    2. 응답 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        return Promise.reject(error);
    }
);

export default instance;